import Image from "next/image";
import Head from "next/head";

import { MarketplaceLink } from "@components/MarketplaceLink";
import { MarketHeader } from "@components/MarketHeader";
import { Footer } from "@components/Footer";

import notFoundImg from "@public/img/404.png";
import gradientImg from "@public/img/gradient_light.jpg";

export default function Custom404() {
  return (
    <>
      <MarketHeader />
      <Head>
        <title>{`Error 404 | Spaceship Marketplace`}</title>
      </Head>
      <main className="relative">
        <section className="relative py-16 dark:bg-jacarta-800 md:py-24">
          <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
            <Image src={gradientImg} alt="" className="h-full w-full" />
          </picture>
          <div className="container">
            <div className="mx-auto max-w-lg text-center">
              <Image
                src={notFoundImg}
                alt="404 image"
                className="mb-16 inline-block"
              />

              <h1 className="mb-6 font-display text-4xl text-jacarta-700 dark:text-white md:text-6xl">
                Page Not Found!
              </h1>

              <p className="mb-12 text-lg leading-normal text-jacarta-500 dark:text-jacarta-300">
                Oops! The page you are looking for does not exist. It might have
                been moved or deleted.
              </p>
              <MarketplaceLink
                href="/"
                className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
              >
                Navigate Back Home
              </MarketplaceLink>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
